import { useState } from "react";
import AceEditor from "react-ace";
import { Table, Spinner } from "react-bootstrap";
import { FaBan, FaPlay, FaSave } from "react-icons/fa";
import Split from "react-split";
import "brace/mode/mysql";
import "brace/theme/gruvbox";
import "ace-builds/src-noconflict/ext-language_tools";

function Editor() {
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    isFail: false,
    data: "",
  });

  const [queries, setQueries] = useState(`-- Create
CREATE TABLE users (
  id INTEGER PRIMARY KEY,
  name TEXT NOT NULL,
  age INTEGER NOT NULL,
  city TEXT NOT NULL,
  country TEXT NOT NULL
);

-- Insert
INSERT INTO users VALUES (1, 'Alice', 49, "A", "X");
INSERT INTO users VALUES (2, 'Bob', 22, "B", "Y");
INSERT INTO users VALUES (3, 'Eve', 25, "C", "Z");

-- Fetch
SELECT * FROM users WHERE age >= 25;`);

  function onChange(value) {
    setQueries(value);
  }

  return (
    <>
      <Split
        className="split"
        id="wrapper"
        gutterSize={10}
        style={{
          display: "flex",
        }}
      >
        <div
          id="editor"
          style={{
            minWidth: "250px",
            borderRight: "1px solid rgba(132, 175, 116, 0.7)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "50px",
              borderBottom: "1px solid rgba(132, 175, 116, 0.3)",
              backgroundColor: "#252728",
            }}
          >
            <div
              style={{
                marginLeft: "20px",
                fontSize: "18px",
                fontWeight: "600",
                color: "#B7BA36",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src="/logo.png" width="22" height="22" alt="logo" />
              <span
                style={{
                  marginLeft: "8px",
                  fontWeight: "700",
                  marginTop: "2px"
                }}
              >
                SQLitor
              </span>
            </div>
            <div>
              <FaBan
                style={{
                  color: "#B7BA36",
                  fontSize: "18px",
                  margin: "0px 10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setQueries("");
                  setResult([]);
                }}
                title="Clear"
              />
              <FaPlay
                style={{
                  color: "#B7BA36",
                  fontSize: "16px",
                  margin: "0px 10px",
                  cursor: "pointer",
                }}
                onClick={async () => {
                  setIsLoading(true);
                  const rawResponse = await fetch(
                    window.location.host === "localhost:3000"
                      ? "http://localhost:2022"
                      : "https://sqlitor-server.herokuapp.com/",
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({ query: queries }),
                    }
                  );
                  const getData = await rawResponse.json();
                  if (getData.status === "1") {
                    setError({
                      isFail: false,
                      data: "",
                    });
                    setResult(getData.data);
                  } else {
                    setError({
                      isFail: true,
                      data: getData.data,
                    });
                  }
                  setIsLoading(false);
                }}
                title="Execute"
              />
              <FaSave
                style={{
                  color: "#B7BA36",
                  fontSize: "18px",
                  margin: "0px 10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const element = document.createElement("a");
                  element.setAttribute(
                    "href",
                    "data:text/plain;charset=utf-8, " +
                      encodeURIComponent(queries)
                  );
                  element.setAttribute(
                    "download",
                    `sqlitor${new Date().getTime()}.sql`
                  );
                  document.body.appendChild(element);
                  element.click();
                }}
                title="Save"
              />
            </div>
          </div>
          <AceEditor
            mode="mysql"
            theme="gruvbox"
            onChange={onChange}
            name="editor"
            editorProps={{ $blockScrolling: false }}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
            }}
            width="100%"
            height="calc(100vh - 100px)"
            value={queries}
          />
        </div>
        <div
          style={{
            minWidth: "250px",
            borderLeft: "1px solid rgba(132, 175, 116, 0.7)",
          }}
        >
          <div
            id="output"
            style={{
              height: "50px",
              backgroundColor: "#252728",
              display: "flex",
              alignItems: "center",
              fontSize: "18px",
              fontWeight: "600",
              color: "#B7BA36",
              borderBottom: "1px solid rgba(132, 175, 116, 0.3)",
              paddingLeft: "20px",
            }}
          >
            <div>Output</div>
            {isLoading && (
              <Spinner
                className="mx-3"
                size="sm"
                animation="border"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>
          <div
            id="editor"
            style={{
              padding: "20px",
              height: "calc(100vh - 100px)",
              backgroundColor: "#1E2021",
              color: "#EBDAB4",
            }}
          >
            {!error.isFail ? (
              result.length > 0 ? (
                <Table responsive className="table-borderless">
                  <thead>
                    <tr>
                      {result[0].columns.map((column, index) => (
                        <th key={index}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {result[0].values.map((value, index) => (
                      <tr key={index}>
                        {value.map((cell, cellIndex) => (
                          <td key={cellIndex}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p
                  style={{
                    padding: "10px 20px 20px 20px",
                  }}
                >
                  ...
                </p>
              )
            ) : (
              <p
                style={{
                  padding: "20px",
                }}
              >
                {error.data}
              </p>
            )}
          </div>
        </div>
      </Split>
      <div
        style={{
          backgroundColor: "#1E2021",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#b7ba36",
          borderTop: "1px solid rgba(132, 175, 116, 0.3)",
          fontSize: "15px",
        }}
      >
        Designed and Developed by
        <a
          href="https://github.com/varunpbardwaj"
          target="_blank"
          rel="noreferrer"
        >
          Varun Bardwaj
        </a>
      </div>
    </>
  );
}

export default Editor;
